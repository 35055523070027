.browse {
    /* height: 100vh; */
    padding: 5pt;
    padding-bottom: 25pt;
}

.browse .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    width: 100%;
    padding: 5pt;
    margin-bottom: 15px;
    overflow-x: scroll;
}

.browse .filters::-webkit-scrollbar {
    display: none;
}

.browse .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 5pt 10pt;
    border-radius: 15px;
    background-color: #ededed;
    /* box-shadow: 0 0 10pt rgba(0, 0, 0, 0.1); */
    cursor: pointer;
}

.browse .filter select {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 600;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align-last: right;
}

.browse .filter option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.browse .filter h2 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    color: #666;
}

.browse .results {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 15px;
    width: 100%;
}

.browse .results .course {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 28pt);
    padding: 12pt 12pt;
    border-radius: 15px;
    background-color: #ededed;
    /* box-shadow: 0 0 10pt rgba(0, 0, 0, 0.1); */
    gap: 10px;
}

.browse .results .course .info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-right: auto;
    gap: 5px;
}

.browse .results .course h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.browse .results .course h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    font-family: monospace;
}