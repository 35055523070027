.week-view {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-around; */
    width: 100%;
    margin: 0;
    padding: 0;
}

.times {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    margin-top: 30px;
}

.time {
    width: 100%;
    height: 50pt;
    display: flex;
    flex-direction: row;
}

.time h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #777;
    width: 30px;
}

.time hr {
    width: 100%;
    height: 1px;
    border: 0;
    background: #e0e0e0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
}

.week-view .events {
    position: absolute;
    left: calc(5% + 30px);
    width: calc(95% - 50px);
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
}

.week-view .day {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: calc((95vw - 50px) / 5);
    margin: 0;
    padding: 0;
}

.week-view .day > h2 {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
    font-size: 1rem;
    color: #777;
}

.week-view .events-holder {
    width: 100%;
    height: calc(50pt);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
}

.week-view .event {
    height: calc(100% * var(--length) - 8pt - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* width: calc(100% - 16pt - 6px); */
    padding: 4pt 8pt;
    margin: 5px 3px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-top: 6px;
    margin-bottom: 13px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.week-view .event h3 {
    margin: 0;
    padding: 0;
    font-size: 0.65rem;
    font-weight: 600;
}

.week-view .event h4 {
    margin: 0;
    padding: 0;
    font-size: 0.6rem;
    font-weight: 400;
    font-family: monospace;
    color: #777;
}