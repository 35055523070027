.App {
  margin: 0;
  padding: 0;
  margin-top: 4vh;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: calc(90% - 35px);
  margin-left: calc(5% + 5px);
  margin-bottom: 14px;
  position: relative;
}

.search-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-right: auto;
}

input {
  border: none;
  background: transparent;
  font-size: 1.4rem;
  font-weight: 500;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  /* margin-bottom: 14px; */
  /* width: calc(90% - 35px); */
  width: 100%;
}

.search-clear {
  /* position: absolute;
  right: calc(5% + 20px);
  top: calc(4vh + 6px); */
  font-size: 1.2rem;
  color: #777;
  padding: 0;
  margin-left: -22px;
  margin-top: 13px;
}

.session-dropdown {
  margin-left: 30px;
}

.browse {
  width: calc(90% - 10px);
  margin-left: calc(5% + 5px);
}

.browse .options {
  display: grid;
  width: 100%;
  gap: 10px;
}

.selected-courses {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 11px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.selected-courses::-webkit-scrollbar {
  display: none;
}

.selected-courses .course {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 3%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-weight: 600;
  background: #ededed;
  gap: 20px;
}

.selected-courses .course h2 {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
  opacity: 0.3;
}

.selected-courses .course:first-of-type {
  margin-left: 5%;
}

.selected-courses .course:last-of-type {
  margin-right: 5%;
}

.option-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  border-radius: 12px;
  border: none;
  margin-bottom: 15px;
  background: #ededed;
}

.timetable-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
}

.multiselect {
  background: #ededed;
  border-radius: 15px;
  height: 40px;
  margin-left: 5%;
  margin-top: 15px;
  padding-top: 5px;
  padding-left: 5px;
  width: calc(90% - 90px);
}

.optimizations-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 70px;
  height: 45px;
  margin-top: 15px;
  margin-left: 10px;
  border-radius: 15px;
  z-index: 200;
  background: #ededed;
}

.optimizations-dropdown-options {
  position: absolute;
  right: 25px;
  background: #ededed;
  top: 145px;
  border-radius: 15px;
  width: 170px;
  padding: 3pt 10pt;
  transform: scale(0.53);
  opacity: 0;
  z-index: -100;
  box-shadow: 0 0 30px rgba(0,0,0,0.2);
  transform-origin: top right;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.optimizations-dropdown-options.active {
  z-index: 200;
  transform: scale(1);
  opacity: 1;
}

.optimizations-dropdown-options .optimization {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 500;
  gap: 30px;
  cursor: pointer;
  font-size: 0.9rem;
}

.optimizations-dropdown-options hr {
  margin: 0;
  padding: 0;
  width: 100%;
}

.optimizations-dropdown-options .optimization h3 {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.optimizations-dropdown-options .optimization .icon {
  margin-right: 12px;
  width: 10px;
  margin-left: auto;
}

.multiselect .select-box {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: calc(100% - 5px);
  width: calc(79% / var(--number-of-states));
  border-radius: 12px;
  background: white;
  margin-left: calc(var(--multiselect-state) * (100% / (var(--number-of-states)) - 3px));
  transition: all 0.3s ease;
}

.multiselect .icons {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  height: 100%;
  width: calc(100% - 5px);
  font-size: 1.2rem;
  transform: translateY(-37px);
}

.multiselect .icons .icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searched-courses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 5%;
  width: calc(90% - 10px);
}

.searched-courses .course {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 50pt;
  padding: 0;
  padding-top: 5pt;
  padding-bottom: 5pt;
  transition: all 0.3s ease-in-out;
}

.searched-courses .course .info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.searched-courses .course h2 {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 1rem;
}

.searched-courses .course p {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 0.8rem;
  color: #777;
}

.searched-courses .add {
  padding: 0 10px;
}

.searched-courses hr {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.invisible {
  display: none;
}